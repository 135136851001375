<template>
  <b-container fluid class="overflowable" :style="{ height: this.$store.state.availableTableHeight }">
    <b-row>
      <b-col>
        <transactions-searcher></transactions-searcher>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <TransactionsExporter></TransactionsExporter>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <transaction-search-result></transaction-search-result>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import TransactionsExporter from "@/components/transactions/TransactionsExporter.vue";
import TransactionsSearcher from "@/components/transactions/TransactionsSearcher.vue";
import TransactionSearchResult from "@/components/transactions/TransactionSearchResult.vue";

export default {
  components: {
    TransactionsExporter,
    TransactionsSearcher,
    TransactionSearchResult
  },
  mounted() {
    this.$store.commit("transactions/clear");
  }
};
</script>
