<style>
.checkbox {
  text-align: center;
}
</style>

<template>
  <b-overlay :show="$store.state.transactionPages.isLoading">
    <b-row class="mb-3">
      <b-col>
        <b-button v-b-modal.close-transactions-modal :disabled="!anyTransactionsSelected">
          {{ $t("transactions.general-result.close-btn-caption") }}
        </b-button>
      </b-col>
    </b-row>
    <b-table
      ref="transaction-table"
      show-empty
      :fields="fields"
      :items="transactions"
      :per-page="itemCountPerPage"
      @head-clicked="onTableHeadClicked"
      @sort-changed="onChangedSort"
      :empty-text="$t('transactions.general-result.table.empty-text')"
      responsive
    >
      <template slot="top-row">
        <td></td>
        <td>
          <b-form-datepicker
            v-model="filter.createdAt"
            :locale="$i18n.locale"
            placeholder=""
            value-as-date
            class="mb-1"
            boundary="window"
          ></b-form-datepicker>
          <b-button @click="filter.createdAt = null">{{ $t("transactions.reset-date-filter") }}</b-button>
        </td>
        <td>
          <b-input v-model.trim="filter.driver"></b-input>
        </td>
        <td>
          <b-input v-model.trim="filter.vehicle"></b-input>
        </td>
        <td>
          <b-input v-model.trim="filter.goodsTitle"></b-input>
        </td>
        <td>
          <b-input v-model.trim="filter.sold"></b-input>
        </td>
        <td></td>
        <td>
          <b-input v-model.trim="filter.manager"></b-input>
        </td>
        <td>
          <b-form-datepicker
            v-model="filter.closedAt"
            :locale="$i18n.locale"
            placeholder=""
            value-as-date
            class="mb-1"
            boundary="window"
          ></b-form-datepicker>
          <b-button @click="filter.closedAt = null">{{ $t("transactions.reset-date-filter") }}</b-button>
        </td>
      </template>
      <template v-slot:cell(selected)="row">
        <b-form-checkbox
          class="checkbox"
          size="lg"
          :checked="$store.getters['transactionPages/isSelectedTransaction'](row.item.id)"
          @change="onSelectTransaction($event, row.item.id)"
        ></b-form-checkbox>
      </template>
      <template v-slot:cell(createdAt)="row">
        {{ $d(Date.parse(row.item.createdAt)) }}
      </template>
      <template v-slot:cell(driver)="row">
        {{ row.item.driver.userName }}
      </template>
      <template v-slot:cell(vehicle)="row">
        {{ row.item.vehicle.registrationNumber }}
      </template>
      <template v-slot:cell(goodsInfo)="row">
        {{ row.item.goodsInfo.title[$i18n.locale] }}
      </template>
      <template v-slot:cell(status)="row">
        <h5>
          <b-badge v-if="row.item.closedAt != null" pill variant="success">
            {{ $t("transactions.general-result.table.status-closed") }}
          </b-badge>
          <b-badge v-else pill variant="danger">
            {{ $t("transactions.general-result.table.status-opened") }}
          </b-badge>
        </h5>
      </template>
      <template v-slot:cell(manager)="row">
        <span v-if="row.item.manager">{{ row.item.manager.userName }}</span>
      </template>
      <template v-slot:cell(closedAt)="row">
        <span v-if="row.item.closedAt">{{ $d(Date.parse(row.item.closedAt)) }}</span>
      </template>
    </b-table>
    <b-pagination
      v-model="currentPage"
      :total-rows="$store.getters['transactionPages/totalRowCount']"
      :per-page="itemCountPerPage"
    ></b-pagination>
    <b-modal
      id="close-transactions-modal"
      @ok.prevent="closeTransactions"
      size="lg"
      :title="$t('transactions.general-result.modal.title')"
      :ok-title="$t('transactions.general-result.modal.ok-btn-caption')"
      :cancel-title="$t('transactions.general-result.modal.cancel-btn-caption')"
    >
      <template>
        {{ $t("transactions.general-result.modal.body") }}
      </template>
    </b-modal>
  </b-overlay>
</template>

<script>
import api from "@/services/api.js";
import { RELOAD_TRANSACTION_LIST } from "@/const/events.js";
import eventBus from "@/services/event-bus.js";

export default {
  data() {
    return {
      filter: {
        createdAt: null,
        driver: "",
        vehicle: "",
        goodsTitle: "",
        sold: "",
        status: "",
        manager: "",
        closedAt: null
      },
      selectAll: false,
      currentPage: 1
    };
  },
  computed: {
    itemCountPerPage() {
      return this.$store.getters["settings/appearance/commonTableRowCount"];
    },
    transactions() {
      return this.$store.getters["transactionPages/transactions"];
    },
    selectedTransactions() {
      return this.$store.getters["transactionPages/selectedTransactions"];
    },
    anyTransactionsSelected() {
      return this.selectedTransactions.length > 0;
    },
    allTransactionsSelected() {
      let result = this.selectedTransactions.length == this.transactions.length;
      return result;
    },
    fields() {
      return [
        { key: "selected", label: "", sortable: false },
        { key: "createdAt", label: this.$t("transactions.general-result.table.created-at"), sortable: true },
        { key: "driver", label: this.$t("transactions.general-result.table.driver"), sortable: true },
        { key: "vehicle", label: this.$t("transactions.general-result.table.vehicle"), sortable: true },
        { key: "goodsInfo", label: this.$t("transactions.general-result.table.goods-info"), sortable: true },
        { key: "sold", label: this.$t("transactions.general-result.table.sold"), sortable: true },
        { key: "status", label: this.$t("transactions.general-result.table.status"), sortable: true },
        { key: "manager", label: this.$t("transactions.general-result.table.manager"), sortable: true },
        { key: "closedAt", label: this.$t("transactions.general-result.table.closed-at"), sortable: true }
      ];
    }
  },
  methods: {
    onChangedSort(ctx) {
      this.$store.commit("transactionPages/clear");
      this.currentPage = 1;
      this.$store.commit("transactionPages/setSortBy", ctx.sortBy);
      this.$store.commit("transactionPages/setSortDesc", ctx.sortDesc);
      this.loadTransactions();
    },
    onTableHeadClicked(column) {
      if (column == "selected") {
        this.selectAll = !this.selectAll;
        this.$store.commit("transactionPages/setSelectedState", {
          transactionIds: this.transactions.map(x => x.id),
          isSelected: this.selectAll
        });
      }
    },
    onSelectTransaction(evt, id) {
      this.$store.commit("transactionPages/setSelectedState", {
        transactionIds: [id],
        isSelected: evt
      });
    },
    closeTransactions() {
      api
        .closeTransactions(this.selectedTransactions)
        .then(() => {
          eventBus.$emit(RELOAD_TRANSACTION_LIST);
          this.$bvModal.hide("close-transactions-modal");
        })
        .catch(err => {
          if (err.data.errorCode) {
            alert(this.$t("transactions.general-result.error." + err.data.errorCode));
          } else {
            alert(this.$t("transactions.general-result.error.general"));
          }
        });
    },
    loadTransactions() {
      this.$store.dispatch("transactionPages/loadTransactions", {
        filter: this.filter,
        page: this.currentPage,
        locale: this.$i18n.locale
      });
    }
  },
  watch: {
    filter: {
      handler: function() {
        this.$store.commit("transactionPages/clear");
        this.currentPage = 1;
        this.loadTransactions();
      },
      deep: true
    },
    currentPage() {
      this.loadTransactions();
    }
  }
};
</script>
