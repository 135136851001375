<template>
  <b-row class="mb-2">
    <b-col>
      <b-button @click="exportTransactions">{{ $t("transactions.excel-export.btn") }}</b-button>
    </b-col>
  </b-row>
</template>

<script>
import file from "@/services/file.js";

export default {
  methods: {
    exportTransactions() {
      this.$store
        .dispatch("transactionPages/exportTransactions", { locale: this.$i18n.locale })
        .then(resp =>
          file.download({
            fileName: this.$t("transactions.excel-export.download-file-name"),
            content: resp.data,
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          })
        )
        .catch(() => alert(this.$t("transactions.excel-export.general-error")));
    }
  }
};
</script>
