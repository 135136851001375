<style>
.gray-bg {
  background-color: #f3f3f3;
  border-radius: 3px;
}
</style>
<template>
  <div>
    <div class="gray-bg p-2 mb-3">
      <h5 class="mb-3">{{ $t("transactions.general-searcher.date-of-sale") }}</h5>
      <b-row>
        <b-col md="6">
          <b-form-datepicker
            v-model="createdFrom"
            :locale="this.$i18n.locale"
            @input="onSearchQueryChanged"
          ></b-form-datepicker>
        </b-col>
        <b-col md="6">
          <b-form-datepicker
            v-model="createdTo"
            :locale="this.$i18n.locale"
            @input="onSearchQueryChanged"
          ></b-form-datepicker>
        </b-col>
      </b-row>
    </div>
    <b-row class="mb-3 p-2">
      <b-col>
        <b-form-checkbox v-model="openedOnly" @change="onOpenedOnlyChanged" switch>
          {{ $t("transactions.general-searcher.opened-only") }}
        </b-form-checkbox>
      </b-col>
    </b-row>
    <div class="gray-bg p-2 mb-2">
      <h5 class="mb-3">{{ $t("transactions.general-searcher.settlement-date") }}</h5>
      <b-row class="mb-2">
        <b-col class="mt-1" md="2">
          <b-form-checkbox v-model="closedOnly" @change="onClosedOnlyChanged" switch>
            {{ $t("transactions.general-searcher.closed-only") }}
          </b-form-checkbox>
        </b-col>
        <b-col md="5">
          <b-form-datepicker
            :disabled="!closedOnly"
            v-model="closedFrom"
            :locale="this.$i18n.locale"
            @input="onSearchQueryChanged"
          ></b-form-datepicker>
        </b-col>
        <b-col md="5">
          <b-form-datepicker
            :disabled="!closedOnly"
            v-model="closedTo"
            :locale="this.$i18n.locale"
            @input="onSearchQueryChanged"
          ></b-form-datepicker>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { RELOAD_TRANSACTION_LIST } from "@/const/events.js";
import eventBus from "@/services/event-bus.js";

export default {
  data() {
    var dateMonthBefore = new Date();
    dateMonthBefore.setMonth(dateMonthBefore.getMonth() - 1);
    return {
      // NOTE should be changed in ~8k years to .slice(0, 11)
      createdFrom: dateMonthBefore.toISOString().slice(0, 10),
      createdTo: new Date().toISOString().slice(0, 10),
      openedOnly: false,
      closedOnly: false,
      closedFrom: dateMonthBefore.toISOString().slice(0, 10),
      closedTo: new Date().toISOString().slice(0, 10)
    };
  },
  methods: {
    collectSearchQuery() {
      let result = {};
      result.createdFrom = this.createdFrom;
      result.createdTo = this.createdTo;
      result.openedOnly = this.openedOnly;
      result.closedOnly = this.closedOnly;
      if (this.closedOnly) {
        result.closedFrom = this.closedFrom;
        result.closedTo = this.closedTo;
      }
      return result;
    },
    onOpenedOnlyChanged(newValue) {
      if (newValue) {
        this.closedOnly = false;
      }
      this.openedOnly = newValue;
      this.onSearchQueryChanged();
    },
    onClosedOnlyChanged(newValue) {
      if (newValue) {
        this.openedOnly = false;
      }
      this.closedOnly = newValue;
      this.onSearchQueryChanged();
    },
    onSearchQueryChanged() {
      this.$store.commit("transactionPages/clear");
      let searchParams = this.collectSearchQuery();
      this.$store.dispatch("transactionPages/loadTransactions", {
        params: searchParams
      });
    }
  },
  mounted() {
    eventBus.$on(RELOAD_TRANSACTION_LIST, this.onSearchQueryChanged);
    this.onSearchQueryChanged();
  },
  beforeDestroy() {
    eventBus.$off(RELOAD_TRANSACTION_LIST, this.onSearchQueryChanged);
  }
};
</script>
